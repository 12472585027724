import { $derived, $observer } from '@tw/snipestate';
import { $currentShopId } from '$stores/$shop';
import _db, { toArray } from 'utils/DB';
import type { WillyDashboardElement } from 'components/Willy/types/willyTypes';
import { $globalDashboards, $loadingGlobalDashboards } from './$globalDashboards';
import { $userId, $userRoles, $isSimpleUser, $isTwGlobalDashboardCreatorClaim } from '../$user';
import { $favoriteDashboards } from './$favoriteDashboards';
import { WillyElementType } from 'components/Willy/types/willyTypes';
import { DashboardPermissionsManager } from 'components/Willy/dashboardManagment/permissions-management/DashboardPermissionsManager';
import { LimitedAccess } from 'components/Nav/components/navs/AlanNav/LimitedAccessTooltip';
import { $creditsLeft } from './$credits';

const $shopDashboardsSnapshot = $observer(
  { loading: true, data: [] as WillyDashboardElement[] },
  (get, set) => {
    const shopId = get($currentShopId);
    const isTwGlobalDashboardCreatorClaim = get($isTwGlobalDashboardCreatorClaim);
    const userId = get($userId);
    if (!shopId || !userId) return;

    return _db(shopId)
      .collection('willy_dashboards')
      .onSnapshot((querySnapshot) => {
        set({
          loading: false,
          data: toArray(querySnapshot)
            .filter((a) => !a.isHide || isTwGlobalDashboardCreatorClaim)
            .sort((a, b) => a.order - b.order),
        });
      });
  },
);

const $privateShopDashboards = $observer(
  { data: [] as WillyDashboardElement[], loading: true, error: null as string | null },
  (get, set) => {
    const userId = get($userId);
    if (!userId || !get($currentShopId)) return;

    const { loading: snapshotLoading, data: snapshotData } = get($shopDashboardsSnapshot);
    const loadingGlobalDashes = get($loadingGlobalDashboards);
    if (snapshotLoading || loadingGlobalDashes) return;

    const globalDashboards = get($globalDashboards);
    const userRoles = get($userRoles);
    const favoriteDashboards = get($favoriteDashboards);

    const data = snapshotData.reduce((acc, x) => {
      let isLocked = false;
      let isProviderLocked = false;

      const globalDashboard =
        (!!x.globalDashboardId && globalDashboards.find((d) => d.id === x.globalDashboardId)) ??
        false;

      if (globalDashboard) {
        isLocked = globalDashboard.isLocked ?? false;
        isProviderLocked = globalDashboard.isProviderLocked ?? false;
      }
      if (isProviderLocked) return acc;

      const perms = DashboardPermissionsManager.computeDashPerms(
        x.generalAccess?.type,
        x.generalAccess?.permission,
        x.userPermissions,
        userRoles,
        userId,
      );

      acc.push({
        ...x,
        isLocked,
        isProviderLocked,
        canView: perms.canView,
        canEdit: perms.canEdit,
        canDelete: perms.canDelete,
        canEditPerms: perms.canEditPerms,
        isGlobal: false,
        type: 'dashboard' as WillyElementType,
        isFavorite: favoriteDashboards.includes(x.id),
        isCustomView: false,
      });
      return acc;
    }, [] as WillyDashboardElement[]);

    set({ ...get(), data, loading: false });
  },
);

export const $shopDashboards = $derived((get) => get($privateShopDashboards).data);
export const $dashboardIds = $derived((get) => new Set(get($shopDashboards).map((d) => d.id)));
export const $shopDashesLoading = $derived((get) => get($privateShopDashboards).loading);

export const $createDashboardAccess = $derived((get): LimitedAccess => {
  if (!get($creditsLeft)) return 'no-credits';
  return 'can-access';
});

export const $addTemplateAccess = $derived((get) => {
  if (get($isTwGlobalDashboardCreatorClaim)) return 'can-access';

  const canCreate = get($createDashboardAccess);
  if (['can-access', 'no-access'].includes(canCreate)) return canCreate;

  return !get($creditsLeft) ? 'no-credits' : 'can-access';
});

export const $canCreateDashboard = $derived((get) => {
  const createDashboardAccess = get($createDashboardAccess);
  return createDashboardAccess === 'can-access';
});

export const $canCreateAndMoveFolders = $derived((get): LimitedAccess => {
  if (get($isSimpleUser)) return 'no-access';
  return 'can-access';
});
